import React, { Component } from 'react';
import logo from './logo.svg';
import StickyFooter from './components/StickyFooter';
import shipShow from './assets/ship-show.gif';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="container">
        <img src={shipShow} className="center" />

        <StickyFooter />
      </div>
    );
  }
}

export default App;
