import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      style={{ textAlign: 'center' }}
    >
      {'Copyright © '}
      <Link color="inherit" href="http://shipshow.tv" target={'_blank'}>
        Ship Show Studios
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <footer
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        marginTop: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // minHeight: '100vh',
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[700]
                : theme.palette.grey[900],
          }}
        >
          <Container maxWidth="sm">
            {/* <Typography variant="body1">
            My sticky footer can be found here.
          </Typography> */}
            <Copyright />
          </Container>
        </Box>
      </Box>
    </footer>
  );
}
